import { useTranslate } from "@tolgee/react";
import classNames from "classnames";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActivityStatus, useVisitByIdQuery } from "../../api/generated/graphql";
import { activitiesForVisit } from "../../utils/activitiesUtils";
import { activitiesConfirmed } from "../../typewriter/segment";
import { GradientButton } from "../../components/core";
import { Activity } from "../../types";
import { Activities } from "../../components/core";

type ActivityStatusMap = {
  [key: string]: ActivityStatus;
};

const createStatusMap = (activities: Activity[]): ActivityStatusMap => {
  const statusMap: ActivityStatusMap = {};
  activities.forEach((activity) => {
    statusMap[activity.activityId] = activity.status;
  });
  return statusMap;
};

const clockOutAvailable = (statusMap: ActivityStatusMap): boolean => {
  const notStarted = Object.values(statusMap).find(
    (s) => s === ActivityStatus.NotStarted,
  );
  return !notStarted;
};

export const ConfirmActivities = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { data, loading, error } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
  });

  const onSuccess = () => {
    navigate({
      pathname: `/visit/${id}/clockout`,
      search: searchParams.toString(),
    });
    activitiesConfirmed({});
    return;
  };

  const onCancel = () => {
    navigate(-1);
    return;
  };

  if (loading) {
    return <div>Loading visit data</div>;
  }

  if (error) {
    return <div>Error loading visit data</div>;
  }

  if (!data?.visitById) {
    return <div>No visit data</div>;
  }

  if (!id) {
    return <div>No visit id</div>;
  }

  const activities = activitiesForVisit(data.visitById);
  const statusMap = createStatusMap(activities);
  const canClockOut = clockOutAvailable(statusMap);

  return (
    <div className="flex bg-main">
      <div className="z-10 flex grow flex-col">
        <Activities
          activities={activities}
          visitStatus={"clockedIn"}
          visitInstanceId={id}
        />
        <div className="fixed bottom-0 flex h-24 w-full grow flex-row items-center justify-center bg-main px-6">
          <div className="w-full flex-auto">
            <GradientButton
              buttonType="clear"
              text={t("cancel")}
              onClick={onCancel}
            />
          </div>
          <div
            className={classNames(
              { "opacity-100": canClockOut },
              { "opacity-40": !canClockOut },
              "w-full flex-auto",
            )}
          >
            <GradientButton
              buttonType="primary"
              text={t("submit")}
              disabled={!canClockOut}
              onClick={onSuccess}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
