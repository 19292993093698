import { useTranslate } from "@tolgee/react";
import { useParams } from "react-router-dom";
import { match } from "ts-pattern";

import { Spinner } from "../../components/common";

import {
  Field,
  RadioField,
  useVisitByIdQuery,
} from "../../api/generated/graphql";
import { Label } from "@frontend/lyng/typography";
import { useDateFormatter } from "@frontend/lyng/utils/dateUtils";
import { useCareContext } from "../../providers";
import { calculateBirthday } from "../../utils/dateUtils";

const FieldElement = ({ field }: { field: Field }) => {
  const { t } = useTranslate();

  const value = match(field)
    .with({ __typename: "CheckboxField" }, ({ id, values }) =>
      values.map((val) => (
        <p
          key={`${id}_${val}`}
          className="text-base font-normal text-label-primary"
        >
          {t(val)}
        </p>
      )),
    )
    .with({ __typename: "RadioField" }, ({ value }) => (
      <p className="text-base font-normal text-label-primary">
        {t(value ?? "--")}
      </p>
    ))
    .with({ __typename: "TextArea" }, ({ value }) => (
      <p className="text-base font-normal text-label-primary">
        {value ?? "--"}
      </p>
    ))
    .with({ __typename: "TextField" }, ({ value }) => (
      <p className="text-base font-normal text-label-primary">
        {value ?? "--"}
      </p>
    ))
    .otherwise(() => null);

  return (
    <li>
      <h2 className="text-xl font-bold text-label-primary">{t(field.name)}</h2>
      {value}
    </li>
  );
};

export const CarePlan = () => {
  const { id } = useParams();
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();
  const { formatDate } = useDateFormatter(viewer?.tenantSettings);

  const { data, loading, error } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
  });

  const birthDate =
    data?.visitById?.careRecipient.careRecipientRoles[0].birthDate;
  const formattedBirthDate = formatDate(birthDate || "");
  const age = calculateBirthday(birthDate || "");

  if (loading || !data) return <Spinner />;
  if (error) return <>Error loading visit data</>;

  const careRecipient = data?.visitById?.careRecipient;
  const fields: Field[] = careRecipient?.carePlan.fields ?? [];

  const careNeeds = fields.find(
    ({ id }) => id === "carePlan.careNeeds",
  ) as RadioField;

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col p-4">
        <h2 className="text-xl font-bold text-label-primary">{`${careRecipient?.firstName} ${careRecipient?.lastName}`}</h2>
        <p className="text-sm font-medium text-label-secondary ">
          {t(careNeeds?.value ?? "")}
        </p>
        {birthDate && (
          <Label size="xs">
            {t("ageAndBirthInfo", { age, formattedBirthDate })}
          </Label>
        )}
        <Label size="xs" className="text-base text-label-primary">
          {careRecipient?.email}
        </Label>
        <Label size="xs" className="text-base text-label-primary">
          {careRecipient?.phone}
        </Label>

        <div className="mt-2 h-px bg-section-divider" />
      </div>
      <ul className="flex flex-col gap-6 px-6">
        {fields.map((field) => (
          <FieldElement key={field.name} field={field} />
        ))}
      </ul>
    </div>
  );
};
