import { useEffect, useState, useMemo } from "react";
import { DateTime, Duration } from "luxon";
import { useTranslate } from "@tolgee/react";

import { formatDurationLoosely } from "../utils/dateUtils";

export const VisitStatus = {
  pending: "pending",
  soon: "soon",
  now: "now",
  late: "late",
  away: "away",
  started: "started",
  over: "over",
  done: "done",
  cancelled: "cancelled",
  error: "error",
  missed: "missed",
} as const;

export type VisitStatus = (typeof VisitStatus)[keyof typeof VisitStatus];

type GetVisitDetailsProps = {
  from: DateTime;
  to: DateTime;
  clockIn: DateTime | null;
  clockOut: DateTime | null;
  readOnly?: boolean;
  cancelled?: boolean;
};

type VisitDetails = {
  status: VisitStatus;
  detailsText: string;
  looseTime: string;
  pillText?: string;
};

export const useRemainingTime = (target: DateTime): Duration => {
  const [remainingTime, setRemainingTime] = useState<Duration>(
    target.diffNow(["days", "hours", "minutes"]),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(target.diffNow(["days", "hours", "minutes"]));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [target]);
  return remainingTime;
};

type GetVisitStatusProps = {
  from: DateTime;
  to: DateTime;
  clockIn: DateTime | null;
  clockOut: DateTime | null;
  cancelled?: boolean;
};

const getVisitStatus = ({
  from,
  to,
  clockIn,
  clockOut,
  cancelled,
}: GetVisitStatusProps): VisitStatus => {
  if (cancelled) return VisitStatus.cancelled;

  if (!clockIn && !clockOut) {
    const startDiff = from.diffNow(["minute"]);
    const minToStart = startDiff.toObject().minutes ?? 0;

    if (minToStart < -15) return VisitStatus.away;
    if (minToStart >= -15 && minToStart < -1) return VisitStatus.late;
    if (minToStart >= -1 && minToStart < 0) return VisitStatus.now;
    if (minToStart <= 15) return VisitStatus.soon;
    if (minToStart < -1440) return VisitStatus.missed;
    return VisitStatus.pending;
  }

  if (clockIn && !clockOut) {
    if (to.diffNow().toMillis() < 0) return VisitStatus.over;
    return VisitStatus.started;
  }

  if (clockIn && clockOut) return VisitStatus.done;

  return VisitStatus.error;
};

export const useGetVisitDetails = ({
  from,
  to,
  readOnly,
  clockIn,
  clockOut,
  cancelled,
}: GetVisitDetailsProps): VisitDetails => {
  const { t } = useTranslate();
  const [data, setData] = useState<VisitDetails>({
    status: VisitStatus.pending,
    detailsText: "",
    looseTime: "",
  });

  const target = useMemo(() => (clockIn ? to : from), [clockIn, to, from]);
  const remaining = useRemainingTime(target);
  const doneTime =
    clockIn && clockOut
      ? clockOut?.diff(clockIn, ["day", "hour", "minute"])
      : null;

  const time = formatDurationLoosely(doneTime || remaining, t);

  useEffect(() => {
    const status = getVisitStatus({
      from,
      to,
      clockIn,
      clockOut,
      cancelled,
    });

    const getData = (): [string, string?] => {
      if (status === VisitStatus.cancelled) {
        if (readOnly) return ["", t("visitCard.cancelled") ?? ""];
        return [t("visitCard.visitCancelled"), t("visitCard.cancelled") ?? ""];
      }

      if (status === VisitStatus.pending) {
        if (readOnly) return [t("visitCard.startsIn", { time })];
        return [t("visitCard.youStartIn", { time })];
      }

      if (status === VisitStatus.soon) {
        if (readOnly) return [t("visitCard.startsIn", { time })];
        return [
          t("visitCard.youStartingSoon"),
          t("visitCard.in", { time }) ?? "",
        ];
      }

      if (status === VisitStatus.now) {
        if (readOnly) return [t("visitCard.startsNow")];
        return [t("visitCard.youStartingNow"), t("visitCard.now") ?? ""];
      }

      if (status === VisitStatus.late) {
        if (readOnly) return [t("visitCard.startsNow")];
        return [
          t("visitCard.youStartingNow"),
          t("visitCard.ago", { time }) ?? "",
        ];
      }

      if (status === VisitStatus.away) {
        if (readOnly) return [t("visitCard.startsNow")];
        return [t("visitCard.notStarted"), t("visitCard.ago", { time }) ?? ""];
      }

      if (status === VisitStatus.started) {
        if (readOnly)
          return [t("visitCard.isThere"), t("visitCard.left", { time }) ?? ""];
        return [t("visitCard.youAreHere"), t("visitCard.left", { time }) ?? ""];
      }

      if (status === VisitStatus.over) {
        if (readOnly)
          return [t("visitCard.isThere"), t("visitCard.over", { time }) ?? ""];
        return [t("visitCard.youAreHere"), t("visitCard.over", { time }) ?? ""];
      }

      if (readOnly) return [t("visitCard.completed"), time];
      return [t("visitCard.completed"), time];
    };

    const [detailsText, pillText] = getData();

    setData({ status, detailsText, pillText, looseTime: time });
  }, [from, to, clockIn, clockOut, readOnly, cancelled, time, t]);

  return data;
};
