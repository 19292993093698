import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import toast from "react-hot-toast";
import { match } from "ts-pattern";
import { DateTime } from "luxon";
import confetti from "canvas-confetti";

import {
  useClockInMutation,
  useVisitByIdQuery,
} from "../../api/generated/graphql";
import { Status } from "../../types";
import { useCareContext } from "../../providers";
import { useGeoLocation } from "../../hooks/geoLocation";

import { Activities } from "../../components/core";
import Detail from "./Detail";
import { Title } from "./Title";

import { hasBirthday } from "../../utils/dateUtils";

import { careStarted, completeCareStarted } from "../../typewriter/segment";
import { VisitNoteButton } from "./VisitNoteButton";
import ExpenseSummary from "./ExpenseSummary";
import {
  LateClockOutForm,
  LateClockOutModal,
} from "../../components/core/lateClockOutModal/LateClockOutModal";
import { visitInfo } from "../../utils/visits";
import { Button, Variant } from "@frontend/lyng/button";
import { Edit } from "@frontend/lyng/assets/icons/24/outline";
import { useFeatureFlag } from "../../providers/FeatureFlags";

const getButtonTypeFromStatus = (status: Status) =>
  match<Status, Variant>(status)
    .with("pending", () => "primary")
    .with("clockedIn", () => "primary")
    .with("clockedOut", () => "tertiary")
    .exhaustive();

export const VisitDetails = () => {
  const { id } = useParams();
  const { t } = useTranslate();
  const { viewer } = useCareContext().state;
  const [visitNote, setVisitNote] = useState<string>("");
  const ff_caregiverCreateAndUpdateVisits = useFeatureFlag(
    "CaregiverCreateAndUpdateVisits",
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  const { data, loading, error } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
  });

  const canUpdateVisits = useMemo(() => {
    return (
      ff_caregiverCreateAndUpdateVisits &&
      viewer?.tenantAccess.offices.some(
        (office) => office.settings.caregiversCanCreateAndUpdateVisits,
      )
    );
  }, [viewer, ff_caregiverCreateAndUpdateVisits]);

  const showEditButton =
    canUpdateVisits &&
    data?.visitById?.clockInTime === null &&
    data.visitById.cancelled === false &&
    DateTime.fromISO(data.visitById.start) > DateTime.now();

  const [clickLoading, setClickLoading] = useState(false);
  const [isLateClockOut, setIsLateClockOut] = useState(false);
  const [showLateClockOutModal, setShowLateClockOutModal] = useState(false);
  const [confettiLaunched, setConfettiLaunched] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<Status>("pending");
  const navigate = useNavigate();
  const [clockInMutation] = useClockInMutation();
  const { getPosition } = useGeoLocation();

  function launchConfetti() {
    const leaves = confetti.shapeFromPath({
      path: "M17.0309 20.9691C12.252 15.7307 14.6025 9.21277 16.3752 6.60862C18.3853 7.78763 22.9635 11.0224 25.1957 14.5291C27.9859 18.9126 27.5763 22.0886 27.8162 25.8252C26.7323 25.9743 23.0045 27.5171 17.0309 20.9691Z",
    });
    const circle = confetti.shapeFromPath({
      path: "M8.76956 4.92278C8.76095 7.13135 6.9631 8.92174 4.75396 8.92174C2.54482 8.92174 0.760945 7.13135 0.769562 4.92278C0.778179 2.71422 2.57603 0.923828 4.78516 0.923828C6.9943 0.923828 8.77818 2.71422 8.76956 4.92278Z",
    });
    const seed = confetti.shapeFromPath({
      path: "M2.03557 1.61957C0.794278 2.99249 0.468548 4.79099 0.840998 6.3767C1.11451 8.68936 5.94901 8.2821 6.50587 7.54295C7.02085 6.85937 7.44627 6.37601 6.85503 3.60102C6.26379 0.82603 3.88799 -0.429281 2.03557 1.61957Z",
    });
    const confettiDefault = {
      particleCount: 300,
      spread: 100,
    };
    confetti({
      ...confettiDefault,
      shapes: [leaves],
      scalar: 2,
      origin: { x: 1, y: 0.3 },
      colors: ["#AFCC8B"],
    });
    confetti({
      ...confettiDefault,
      shapes: [circle],
      scalar: 1,
      origin: { x: 0, y: 0.6 },
      colors: ["#96A7FA"],
    });
    confetti({
      ...confettiDefault,
      shapes: [seed],
      scalar: 1,
      origin: { x: 1, y: 0.9 },
      colors: ["#FFE794"],
    });
  }

  useEffect(() => {
    if (!data?.visitById) {
      return;
    }
    const visit = visitInfo(data?.visitById);

    if (visit.birthDate && hasBirthday(visit.birthDate) && !confettiLaunched) {
      launchConfetti();
      setConfettiLaunched(true);
    }
    setIsLateClockOut(visit.to < DateTime.now());
    setCurrentStatus(visit.status);
  }, [data, confettiLaunched]);

  useEffect(() => {
    const visitNote = localStorage.getItem("visitNote|" + id);
    if (visitNote) {
      setVisitNote(visitNote);
    }
  }, [id]);

  const readOnly = !data?.visitById?.visitorIds.some((id) => id === viewer?.id);

  const navigateClockOut = (scheduled: boolean) => {
    completeCareStarted({});
    // Only go to confirm activities if there are activities to confirm
    setSearchParams({ scheduled: scheduled.toString() });
    if (visit.activities.length > 0) {
      navigate({
        pathname: `/visit/${id}/confirm-activities`,
        search: `?scheduled=${scheduled}`,
      });
      return;
    }
    navigate({
      pathname: `/visit/${id}/clockout`,
      search: `?scheduled=${scheduled}`,
    });
    return;
  };

  const handleClick = () => {
    if (currentStatus === "clockedIn") {
      if (isLateClockOut && visit.shouldShowLateClockOutModal) {
        setShowLateClockOutModal(true);
      } else {
        navigateClockOut(false);
      }
      return;
    }

    setClickLoading(true);
    getPosition()
      .then((position) => {
        const coords = position?.coords;
        const input = {
          visitInstanceId: id ?? "",
          caregiverId: data?.visitById?.visitorIds[0] ?? "",
          clockInLocation:
            coords?.latitude && coords.longitude
              ? {
                  lat: coords.latitude,
                  lng: coords.longitude,
                }
              : undefined,
        };
        return clockInMutation({
          variables: {
            input,
          },
          refetchQueries: ["VisitById"],
        });
      })
      .then(() => {
        setCurrentStatus("clockedIn");
        careStarted({});
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => setClickLoading(false));
  };

  const handleVisitNoteChange = () => {
    navigate(`/visit/${id}/visit-note`);
  };

  if (loading) {
    return <span>Loading visit data</span>;
  }

  if (error) {
    return <span>Error loading visit data</span>;
  }

  if (!data?.visitById) {
    return <span>No visit data</span>;
  }

  const getButtonText = (status: Status) =>
    match(status)
      .with("pending", () => t("visitDetails.pendingButton"))
      .with("clockedIn", () => t("visitDetails.clockedInButton"))
      .with("clockedOut", () => t("visitDetails.clockedOutButton"))
      .exhaustive();

  const saveLateClockOutOption = (form: LateClockOutForm) => {
    navigateClockOut(form.clockOutOption === "scheduled");
    return Promise.resolve();
  };

  const visit = visitInfo(data.visitById);

  const showButton =
    !readOnly &&
    currentStatus !== "clockedOut" &&
    !data.visitById.cancelled &&
    visit.from < DateTime.now().plus({ hours: 12 });

  return (
    <div className="flex pb-10">
      <div className="flex grow flex-col overflow-hidden w-full">
        <div className="absolute top-10 px-20 justify-center">
          <Title visit={visit} />
        </div>
        {showEditButton && (
          <div className="absolute top-0 right-0 py-8 pr-6 mt-2 z-20">
            <Button
              variant="secondary"
              onClick={() => navigate(`./edit`)}
              icon={Edit}
              iconPosition="only"
            />
          </div>
        )}
        <Detail visit={visit} careRecipient={data.visitById.careRecipient} />
        <div className="pb-2 pl-5 pt-6 text-xl font-bold text-title">
          {t("activities")}
        </div>
        <div className="mx-5 mb-6 h-px bg-greyscale-700" />
        <div className="mb-20">
          <Activities
            readOnly={readOnly}
            activities={visit.activities}
            visitStatus={currentStatus}
            visitInstanceId={visit.id}
          />
          {currentStatus === "clockedIn" && (
            <div className="mt-8 px-6">
              <VisitNoteButton
                state={visitNote ? "edit" : "add"}
                onClick={handleVisitNoteChange}
              />
            </div>
          )}
        </div>
        {viewer?.tenantSettings.enableMileageExpense && !readOnly && (
          <ExpenseSummary visit={visit} />
        )}
        {showButton && (
          <div className="fixed bottom-0 w-full grow items-center justify-center pt-2 bg-main">
            <div className="px-6 pt-2 pb-8 bg-greyscale-800">
              <Button
                type="button"
                className="w-full"
                variant={getButtonTypeFromStatus(currentStatus)}
                text={getButtonText(currentStatus)}
                onClick={handleClick}
                loading={clickLoading}
              />
            </div>
          </div>
        )}
      </div>
      <LateClockOutModal
        dismiss={() => setShowLateClockOutModal(false)}
        show={showLateClockOutModal}
        save={saveLateClockOutOption}
      />
    </div>
  );
};
