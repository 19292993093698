import classNames from "classnames";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { useRef, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { frequency } from "../../utils/dateUtils";
import { ActivityCard } from "../../components/core";
import { activityUpdated } from "../../typewriter/segment";
import {
  ActivityStatus,
  Priority,
  useActivityStatusesUpdateMutation,
  useVisitByIdQuery,
} from "../../api/generated/graphql";
import { GradientButton } from "../../components/core";

const defaultReasons = [
  "reason.refused",
  "reason.sleeping",
  "reason.notNeeded",
  "reason.noTime",
  "reason.other",
];

const canContinue = (
  reason: string | null,
  comment: string | null,
): boolean => {
  if (!reason) {
    return false;
  }

  if (reason === "reason.other" && (!comment || comment.length === 0)) {
    return false;
  }

  return true;
};

export const NotCompleteReason = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { id, activityId } = useParams();
  const [reason, setReason] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { data, loading, error } = useVisitByIdQuery({
    variables: {
      visitInstanceId: id ?? "",
    },
  });
  const [actityStatusesUpdate] = useActivityStatusesUpdateMutation({
    refetchQueries: ["VisitById"],
  });

  if (!id || !activityId) {
    return <>No visit id or activity id</>;
  }

  const onSubmit = () => {
    if (!reason) {
      return;
    }

    if (reason === "reason.other" && (!comment || comment.length === 0)) {
      return;
    }

    const reasonTranslated = t(reason);

    toast.promise(
      actityStatusesUpdate({
        variables: {
          visitInstanceId: id,
          input: [
            {
              activityId: activityId,
              status: ActivityStatus.CouldNotComplete,
              comment: reason === "reason.other" ? comment : reasonTranslated,
            },
          ],
        },
      }).then(() => {
        navigate(-1);
      }),
      {
        loading: t("toast.activityStatusUpdating"),
        success: t("toast.activityStatusUpdated"),
        error: t("toast.activityStatusUpdateFailed"),
      },
    );

    activityUpdated({
      activity_completed: false,
      not_completed_reason: `${
        reason === "reason.other" ? comment : reasonTranslated
      }`,
    });
    return;
  };

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const selectedReason = (reasonOption: string) => {
    setReason(reasonOption);

    // if selected other we should focus on text area
    if (reasonOption === "reason.other") {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
    return;
  };

  if (loading) {
    return <div>Loading visit data</div>;
  }

  if (error) {
    return <div>Error loading visit data</div>;
  }

  if (!data) {
    return <div>No visit data</div>;
  }

  const activity = data.visitById?.activities?.find((a) => a.id === activityId);

  if (!activity) {
    return <div>No activity data</div>;
  }

  return (
    <div className="flex bg-main">
      <div className="z-10 flex grow flex-col px-5">
        <ActivityCard
          priority={activity.priority ?? Priority.Optional}
          frequency={frequency(activity.weekdays ?? [], t)}
          title={activity.title}
          description={activity.description}
        />
        <div className="flex flex-col items-center justify-center pt-4">
          {defaultReasons.map((reasonOption) => (
            <div className="my-1 w-full flex-auto" key={reasonOption}>
              <GradientButton
                buttonType={reason === reasonOption ? "primary" : "tertiary"}
                text={t(reasonOption)}
                onClick={() => selectedReason(reasonOption)}
              />
            </div>
          ))}
        </div>
        {reason === "reason.other" && (
          <div className="mb-4 flex flex-col items-center justify-center px-6 text-white">
            <textarea
              ref={textAreaRef}
              autoFocus
              className="h-48 w-full rounded-lg bg-default-gray"
              rows={4}
              onChange={onTextChange}
              aria-label={t("other-reason-comment")}
            />
          </div>
        )}
        <div className="fixed bottom-0 flex h-24 w-full grow flex-row items-center justify-center bg-main px-6">
          <div className="w-full flex-auto">
            <GradientButton
              buttonType="clear"
              text={t("cancel")}
              onClick={onCancel}
            />
          </div>
          <div
            className={classNames(
              { "opacity-100": canContinue(reason, comment) },
              { "opacity-40": !canContinue(reason, comment) },
              "w-full flex-auto",
            )}
          >
            <GradientButton
              buttonType="primary"
              text={t("submit")}
              disabled={!canContinue(reason, comment)}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
